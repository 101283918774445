import {
  nrkLogoNrk,
  nrkChevronDown,
  nrkUserNotloggedin,
  nrkSearch,
  nrkSpinner
} from '@nrk/core-icons'

/**
 * @typedef {Object} MastheadOptions
 * @property {boolean} [dark=false]
 * @property {boolean} [login=true]
 * @property {boolean} [search=true]
 * @property {boolean} [minimal=false]
 * @property {string} [nonce]
 * @property {JumpAnchorManualOptions | JumpAnchorAutoOptions | false} [jumpAnchor=false]
 */

/**
 * @typedef {Object} JumpAnchorAutoOptions
 * @property {'auto'} strategy
 * @property {never} [jumpToId='']
 */

/**
 * @typedef {Object} JumpAnchorManualOptions
 * @property {'manual'} strategy
 * @property {`#${string}`} jumpToId MUST include `#`
 */

/**
 * Masthead
 * @param {MastheadOptions} [options]
 * @returns {string} raw html markup
 */
export function Masthead (options) {
  const { dark = false, login = true, search = true, minimal = false, jumpAnchor = false } = options || {}

  let hideMasthead = false
  let isFrontPage = false

  if (typeof window !== 'undefined') {
    const searchParams = new URLSearchParams(window.location.search)
    isFrontPage = window.location.pathname === '/'
    const headerParam = searchParams.get('header')
    hideMasthead = headerParam === 'off'
  }

  return /*html*/ `<header class="nrk-masthead lp_masthead${dark ? ' nrk-masthead--dark' : ''}${
    minimal ? ' nrk-masthead--minimal' : ''
  }" ${hideMasthead ? 'hidden' : ''} data-ec-list="nrkno-meny" data-ec-name="nrkno-masthead">
      ${getJumpAnchor(jumpAnchor)}
      <a class="nrk-masthead__logo" href="${!isFrontPage ? 'https://www.nrk.no/' : '/'}" aria-current="${isFrontPage ? 'page' : 'false'}" ${!isFrontPage ? 'title="Gå til forsiden"' : ''}>
        <div role="img" aria-label="NRK.no">
          ${nrkLogoNrk}
        </div>
      </a>
      <button class="nrk-masthead__bars" aria-label="Meny" aria-expanded="false">
        <svg aria-hidden="true" focusable="false" width="24" height="24" viewBox="0 0 24 24">
          <g fill-rule="evenodd">
            <rect width="20" height="2" x="2" y="4" />
            <rect width="20" height="2" x="2" y="11"/>
            <rect width="20" height="2" x="2" y="18"/>
          </g>
        </svg>
      </button>

      <nav class="nrk-masthead__desktop" hidden>
        <div class="nrk-masthead__apps">
          <ul>
            <li><a href="https://tv.nrk.no/" data-ec-id="https://tv.nrk.no/" data-ec-name="NRK TV">NRK TV</a></li>
            <li><a href="https://radio.nrk.no/" data-ec-id="https://radio.nrk.no/" data-ec-name="NRK Radio">NRK Radio</a></li>
            <li><a href="https://nrksuper.no" data-ec-id="https://nrksuper.no" data-ec-name="NRK Super">NRK Super</a></li>
            <li><a href="https://p3.no" data-ec-id="https://p3.no" data-ec-name="NRK P3">NRK P3</a></li>
            <li><a href="https://yr.no" data-ec-id="https://yr.no" data-ec-name="Yr">Yr</a></li>
          </ul>
        </div>

        <ul class="nrk-masthead__promo">
          <li><a href="https://www.nrk.no/nyheter/" data-ec-id="pp:2.6892" data-ec-name="Nyheter">Nyheter</a></li>
          <li><a href="https://www.nrk.no/sport/" data-ec-id="pp:2.6852" data-ec-name="Sport">Sport</a></li>
          <li><a href="https://www.nrk.no/kultur/" data-ec-id="pp:2.7015" data-ec-name="Kultur">Kultur</a></li>
          <li><a href="https://www.nrk.no/video/humor/" data-ec-id="https://www.nrk.no/video/humor/" data-ec-name="Humor">Humor</a></li>
          <li><a href="https://www.nrk.no/distrikt/" data-ec-id="pp:distrikt/" data-ec-name="Distrikt">Distrikt</a></li>
        </ul>
        <button class="nrk-masthead__more" aria-expanded="false" aria-controls="nrk-masthead__mega">
          Mer 
          <span class="nrk-masthead__more-icon">${nrkChevronDown}<span>
        </button>
        <div class="nrk-masthead__mega" id="nrk-masthead__mega" hidden>
          <div class="nrk-masthead__wrapper">
            <div class="nrk-masthead__sections">
              <div class="nrk-masthead__section">
                <h2><a href="https://www.nrk.no/nyheter/" data-ec-id="pp:2.6892" data-ec-name="Nyheter">Nyheter</a></h2>
                <ul>
                  <li><a href="https://www.nrk.no/nyheter/" data-ec-id="pp:2.6892" data-ec-name="Nyhetssenter">Nyhetssenter</a></li>
                  <li><a href="https://www.nrk.no/norge/" data-ec-id="pp:2.7008" data-ec-name="Norge">Norge</a></li>
                  <li><a href="https://www.nrk.no/urix/" data-ec-id="pp:2.7029" data-ec-name="Urix">Urix</a></li>
                  <li><a href="https://www.nrk.no/debatten/" data-ec-id="pp:2.7880" data-ec-name="Debatten">Debatten</a></li>
                  <li><a href="https://www.nrk.no/kultur/" data-ec-id="pp:2.7015" data-ec-name="Kultur">Kultur</a></li>
                  <li><a href="https://www.nrk.no/livsstil/" data-ec-id="pp:2.7017" data-ec-name="Livsstil">Livsstil</a></li>
                  <li><a href="https://www.nrk.no/viten/" data-ec-id="pp:2.7016" data-ec-name="Viten">Viten</a></li>
                  <li><a href="https://www.nrk.no/dokumentar/" data-ec-id="pp:2.6837" data-ec-name="Dokumentar">Dokumentar</a></li>
                  <li><a href="https://www.nrk.no/ytring/" data-ec-id="pp:2.6831" data-ec-name="Ytring">Ytring</a></li>
                  <li><a href="https://www.nrk.no/sapmi/" data-ec-id="pp:2.7375" data-ec-name="NRK Sápmi">NRK Sápmi</a></li>
                  <li><a href="https://www.nrk.no/kvensk/" data-ec-id="pp:2.7427" data-ec-name="NRK Kvensk">NRK Kvensk</a></li>
                </ul>
              </div>
              <div class="nrk-masthead__section nrk-masthead__section--narrow">
                <h2><a href="https://www.nrk.no/sport/" data-ec-id="pp:2.6852" data-ec-name="Sport">Sport</a></h2>
                <ul>
                  <li><a href="https://www.nrk.no/sport/" data-ec-id="pp:2.6852" data-ec-name="Sportsnyheter">Sportsnyheter</a></li>
                  <li><a href="https://resultater.nrk.no/" data-ec-id="pp:2.7128" data-ec-name="Livesport">Livesport</a></li>
                  <li><a href="https://www.nrk.no/sport/sendeplan/" data-ec-id="pp:2.6851" data-ec-name="Sendeplan">Sendeplan</a></li>
                </ul>
                <h2><a href="https://www.nrk.no/spill/" data-ec-id="pp:2.8030" data-ec-name="Spill">Spill</a></h2>
                <ul>
                  <li><a href="https://www.nrk.no/emne/quiz-1.13834273" data-ec-id="pp:1.13834273" data-ec-name="Quiz">Quiz</a></li>
                  <li><a href="https://www.nrk.no/former-1.17105310" data-ec-id="pp:1.17105310" data-ec-name="Former (minispill)">Former</a></li>
                  <li><a href="https://www.nrk.no/tvers-1.17073958" data-ec-id="pp:1.17073958" data-ec-name="Tvers (minispill)">Tvers</a></li>
                </ul>
              </div>
              <div class="nrk-masthead__section">
                <h2><a href="https://www.nrk.no/distrikt/" data-ec-id="pp:distrikt/" data-ec-name="Distrikt">Distrikt</a></h2>
                <ul>
                  <li><a href="https://www.nrk.no/sorlandet/" data-ec-id="pp:2.6950" data-ec-name="Agder">Agder</a></li>
                  <li><a href="https://www.nrk.no/stor-oslo/" data-ec-id="pp:2.6954" data-ec-name="Akershus">Akershus</a></li>
                  <li><a href="https://www.nrk.no/buskerud/" data-ec-id="pp:2.6974" data-ec-name="Buskerud">Buskerud</a></li>
                  <li><a href="https://www.nrk.no/tromsogfinnmark/" data-ec-id="pp:2.7611" data-ec-name="Finnmark">Finnmark</a></li>
                  <li><a href="https://www.nrk.no/innlandet/" data-ec-id="pp:2.6944" data-ec-name="Innlandet">Innlandet</a></li>
                  <li><a href="https://www.nrk.no/mr/" data-ec-id="pp:2.6946" data-ec-name="M&oslash;re og Romsdal">M&oslash;re og Romsdal</a></li>
                  <li><a href="https://www.nrk.no/nordland/" data-ec-id="pp:2.6947" data-ec-name="Nordland">Nordland</a></li>
                  <li><a href="https://www.nrk.no/stor-oslo/" data-ec-id="pp:2.6954" data-ec-name="Oslo">Oslo</a></li>
                  <li><a href="https://www.nrk.no/rogaland/" data-ec-id="pp:2.6948" data-ec-name="Rogaland">Rogaland</a></li>
                  <li><a href="https://www.nrk.no/vestfoldogtelemark/" data-ec-id="pp:2.7617" data-ec-name="Telemark">Telemark</a></li>
                  <li><a href="https://www.nrk.no/tromsogfinnmark/" data-ec-id="pp:2.7611" data-ec-name="Troms">Troms</a></li>
                  <li><a href="https://www.nrk.no/trondelag/" data-ec-id="pp:2.6943" data-ec-name="Tr&oslash;ndelag">Tr&oslash;ndelag</a></li>
                  <li><a href="https://www.nrk.no/vestfoldogtelemark/" data-ec-id="pp:2.7617" data-ec-name="Vestfold">Vestfold</a></li>
                  <li><a href="https://www.nrk.no/vestland/" data-ec-id="pp:2.7623" data-ec-name="Vestland">Vestland</a></li>
                  <li><a href="https://www.nrk.no/ostfold/" data-ec-id="pp:2.6953" data-ec-name="&Oslash;stfold">&Oslash;stfold</a></li>
                </ul>
              </div>
              <div class="nrk-masthead__section nrk-masthead__section--narrow">
                <h2>Temasider</h2>
                <ul>
                  <li><a href="https://www.nrk.no/video/humor/" data-ec-id="https://www.nrk.no/video/humor/" data-ec-name="NRK Humor">NRK Humor</a></li>
                  <li><a href="https://www.nrk.no/mat/" data-ec-id="pp:2.7009" data-ec-name="NRK Mat">NRK Mat</a></li>
                  <li><a href="https://www.nrk.no/skole/" data-ec-id="pp:2.7159" data-ec-name="NRK Skole">NRK Skole</a></li>
                  <li><a href="https://www.nrkbeta.no/" data-ec-id="https://www.nrkbeta.no" data-ec-name="NRKbeta">NRKbeta</a></li>
                </ul>
                <h2><a href="https://www.nrk.no/informasjon/" data-ec-id="pp:2.7002" data-ec-name="Info">Info</a></h2>
                <ul>
                  <li><a href="https://hjelp.nrk.no/" data-ec-id="https://hjelp.nrk.no" data-ec-name="Hjelp">Hjelp</a></li>
                  <li><a href="https://www.nrk.no/informasjon/" data-ec-id="pp:2.7002" data-ec-name="Informasjon">Informasjon</a></li>
                  <li><a href="https://info.nrk.no/baerekraft" data-ec-id="https://info.nrk.no/baerekraft" data-ec-name="Bærekraft">Bærekraft</a></li>
                  <li><a href="https://info.nrk.no/karriere/" data-ec-id="https://info.nrk.no/karriere" data-ec-name="Karriere">Karriere</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="nrk-masthead__user-and-search-wrapper">
          ${
            login
              ? `<div class="nrk-masthead__user nrk-masthead__user--desktop">
                        <a href="https://www.nrk.no/logginn" hidden>
                          ${nrkUserNotloggedin}
                          <span>Logg på</span>
                        </a>
                      </div>`
              : ''
          }
          ${
            search
              ? `<div class="nrk-masthead__search">
                          <form class="nrk-masthead__search-form" role="search" action="https://www.nrk.no/sok/">
                            <input type="search" name="q" aria-label="S&oslash;k i hele nrk-universet" placeholder="S&oslash;k" autoComplete="off">
                            <button type="submit" aria-label="S&oslash;k">${nrkSearch}</button>
                          </form>
                        </div>`
              : ''
          }
        </div>
      </nav>
      ${
        login
          ? `<div class="nrk-masthead__user nrk-masthead__user--mobile">
                          <a href="https://www.nrk.no/logginn?context=nrkno-masthead-login" hidden>
                            ${nrkUserNotloggedin}
                            <span>Logg på</span>
                          </a>
                        </div>`
          : ''
      }
      <script async src="/logginn.js"></script>
  </header>`
}

/**
 * Returns a jump to content anchor.
 * Note that strategy `auto` requires JavaScript.
 * @param {JumpAnchorManualOptions | JumpAnchorAutoOptions | false} options
 * @returns {string}
 */
function getJumpAnchor (options) {
  if (!options) {
    return ''
  }

  if (options.strategy === 'auto') {
    return '<a id="nrk-masthead__jump-anchor" class="nrk-masthead__jump-anchor nrk-masthead__jump-anchor--disabled" href="#innhold">Hopp til innhold</a>'
  }

  return `<a id="nrk-masthead__jump-anchor" class="nrk-masthead__jump-anchor nrk-masthead__jump-anchor--enabled" href="${options.jumpToId}">Hopp til innhold</a>`
}
